import React from "react"

import About from "../components/home/about"
import ButtonPrimary from "../components/button-primary"
import Educators from "../components/home/educators"
import Events from "../components/home/events"
import Footer from "../components/global/footer"
import Latest from "../components/home/latest"
import Layout from "../components/global/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <Latest />

    <div style={{ padding: "2rem", textAlign: "center" }}>
      <ButtonPrimary buttonLink="/books" buttonText="See All Books" buttonType="secondary" />
      <hr/>
    </div>

    <About />

    {/* <Events /> */}

    <Educators />

    <Footer />
  </Layout>
)

export default IndexPage
