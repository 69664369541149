import React from "react"

import SectionTitle from "../section-title"
import { useStaticQuery } from "gatsby"
import ButtonPrimary from "../button-primary"
import TwitterFeed from "../twitter-feed"

import styles from "../home/educators.module.css"

const Educators = () => {
  const educatorData = useStaticQuery(graphql`
    query educatorData {
      guide0: booksJson(slug: { eq: "marjory-saves-the-everglades" }) {
        discussion_guide {
          publicURL
        }
        title
      }
      guide1: booksJson(slug: { eq: "teachers-march" }) {
        discussion_guide_url
        title
      }
      guide2: booksJson(slug: { eq: "between-the-lines" }) {
        discussion_guide_url
        title
      }
      guide3: booksJson(slug: { eq: "first-generation" }) {
        discussion_guide {
          publicURL
        }
        title
      }
      guide4: booksJson(slug: { eq: "bound-by-ice" }) {
        discussion_guide {
          publicURL
        }
        title
      }
      guide5: booksJson(slug: { eq: "blood-brother" }) {
        discussion_guide {
          publicURL
        }
        title
      }
      guide6: booksJson(slug: { eq: "babe-conquers-the-world" }) {
        discussion_guide {
          publicURL
        }
        title
      }
      guide7: booksJson(slug: { eq: "muckers" }) {
        discussion_guide {
          publicURL
        }
        title
      }
      guide8: booksJson(slug: { eq: "little-joe" }) {
        discussion_guide {
          publicURL
        }
        title
      }
      guide9: booksJson(slug: { eq: "love-is-loud" }) {
        discussion_guide {
          publicURL
        }
        title
      }
      image1: file(
        relativePath: { eq: "images/sandra-with-first-gen-young-readers.jpg" }
      ) {
        publicURL
      }
      image2: file(
        relativePath: { eq: "images/swasey-school-lunch winners-may-2019.jpg" }
      ) {
        publicURL
      }
    }
  `)

  return (
    <section
      id="educators"
      style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
      aria-labelledby="educators-heading"
    >
      <div className="container">
        <div className="row">
          <div className={`column ${styles.column}`}>
            <SectionTitle id="educators-heading" title="Educators" />
            <p>
              Since 2010, <strong>SANDRA NEIL WALLACE</strong> has inspired
              thousands of young students and burgeoning writers as a guest
              author and writing workshop leader. She's been a presenter for the
              literacy non-profit CLiF (Children's Literacy Foundation), and
              builds literacy initiatives as co-founder of{" "}
              <a
                href="https://dailygoodnh.org/"
                target="_blank"
                rel="noreferrer noopener"
              >
                THE DAILY GOOD's
              </a>{" "}
              Summer Feed and Read Aloud program. To best support educators in
              the classroom, Sandra continues to create her popular,
              pre-recorded Book Talks and Read Alouds centered around the hidden
              heroes in the nonfiction picture books she co-creates.
            </p>

            <div className="image-gallery">
              <div className="image-gallery-item">
                <img
                  src={educatorData.image1.publicURL}
                  alt="Sandra with first generation young readers"
                />
              </div>
              <div className="image-gallery-item">
                <img
                  src={educatorData.image2.publicURL}
                  alt="Sandra with Swasey School Lunch winner"
                />
              </div>
            </div>
          </div>
          <div className={`column ${styles.twitterFeed} ${styles.column}`}>
            <aside
              style={{
                border: "2px solid #000022",
                height: "100%",
                overflow: "auto",
              }}
            >
              <div id="fb-root"></div>
              <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v18.0" nonce="gpI6U8XO"></script>
              <div class="fb-page" data-href="https://www.facebook.com/sandraneilwallace" data-tabs="timeline" data-width="" data-height="700" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/sandraneilwallace" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/sandraneilwallace">Sandra Neil Wallace</a></blockquote></div>
            </aside>
          </div>
          <div
            className={`column ${styles.column}`}
            style={{
              backgroundColor: "#eeebd3",
              padding: "2rem",
            }}
          >
            <h3 className="heading-underline" style={{ textAlign: "center" }}>
              Discussion Guides
            </h3>
            <ul className={styles.guideLinks}>
              <li>
                <p>
                  <ButtonPrimary
                    buttonLink={educatorData.guide9.discussion_guide.publicURL}
                    buttonType="tertiary"
                    buttonText={educatorData.guide9.title}
                    target="_blank"
                  />
                </p>
              </li>
              <li>
                <p>
                  <ButtonPrimary
                    buttonLink={educatorData.guide0.discussion_guide.publicURL}
                    buttonType="tertiary"
                    buttonText={educatorData.guide0.title}
                    target="_blank"
                  />
                </p>
              </li>
              <li>
                <p>
                  <ButtonPrimary
                    buttonLink={educatorData.guide1.discussion_guide_url}
                    buttonType="tertiary"
                    buttonText={educatorData.guide1.title}
                    target="_blank"
                  />
                </p>
              </li>
              <li>
                <p>
                  <ButtonPrimary
                    buttonLink={educatorData.guide2.discussion_guide_url}
                    buttonType="tertiary"
                    buttonText={educatorData.guide2.title}
                    target="_blank"
                  />
                </p>
              </li>
              <li>
                <p>
                  <ButtonPrimary
                    buttonLink={educatorData.guide3.discussion_guide.publicURL}
                    buttonType="tertiary"
                    buttonText={educatorData.guide3.title}
                    target="_blank"
                  />
                </p>
              </li>
              <li>
                <p>
                  <ButtonPrimary
                    buttonLink={educatorData.guide4.discussion_guide.publicURL}
                    buttonType="tertiary"
                    buttonText={educatorData.guide4.title}
                    target="_blank"
                  />
                </p>
              </li>
              <li>
                <p>
                  <ButtonPrimary
                    buttonLink={educatorData.guide5.discussion_guide.publicURL}
                    buttonType="tertiary"
                    buttonText={educatorData.guide5.title}
                    target="_blank"
                  />
                </p>
              </li>
              <li>
                <p>
                  <ButtonPrimary
                    buttonLink={educatorData.guide6.discussion_guide.publicURL}
                    buttonType="tertiary"
                    buttonText={educatorData.guide6.title}
                    target="_blank"
                  />
                </p>
              </li>
              <li>
                <p>
                  <ButtonPrimary
                    buttonLink={educatorData.guide7.discussion_guide.publicURL}
                    buttonType="tertiary"
                    buttonText={educatorData.guide7.title}
                    target="_blank"
                  />
                </p>
              </li>
              <li>
                <p>
                  <ButtonPrimary
                    buttonLink={educatorData.guide8.discussion_guide.publicURL}
                    buttonType="tertiary"
                    buttonText={educatorData.guide8.title}
                    target="_blank"
                  />
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Educators
