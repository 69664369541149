import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Container from "./container"
import SectionTitle from "../section-title"

import styles from "./footer.module.css"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          email
          facebook
          instagram
          twitter
        }
      }
      twitter: file(relativePath: { eq: "images/icons/icon--twitter.svg" }) {
        publicURL
      }
      instagram: file(
        relativePath: { eq: "images/icons/icon--instagram.png" }
      ) {
        publicURL
      }
      facebook: file(relativePath: { eq: "images/icons/icon--facebook.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <footer id="contact" className={styles.footer} aria-labelledby="contact-header">
      <div className={styles.footerInner}>
        <Container>
          <SectionTitle id="contact-header" title="Contact" />
          <div className="row">
            <div className={`column ${styles.footerColumn}`}>
              <h3 className="heading-underline">Connect With Sandra</h3>
              <h4 style={{ textTransform: "uppercase" }}>Email</h4>
              <p>{data.site.siteMetadata.email}</p>
              <h4
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "uppercase",
                  transform: "translateX(-15px)",
                }}
              >
                <img
                  src={data.twitter.publicURL}
                  alt=""
                  style={{ width: "25px", margin: "0" }}
                />
                <span style={{ marginLeft: "0.5rem" }}>Twitter</span>
              </h4>
              <p>
                <a
                  href={`https://twitter.com/${data.site.siteMetadata.twitter}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#000022" }}
                >
                  @{data.site.siteMetadata.twitter}
                </a>
              </p>
              <h4
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "uppercase",
                  transform: "translateX(-15px)",
                }}
              >
                <img
                  src={data.facebook.publicURL}
                  alt=""
                  style={{ width: "25px", margin: "0" }}
                />
                <span style={{ marginLeft: "0.5rem" }}>facebook</span>
              </h4>
              <p>
                <a
                  href={`https://facebook.com/${data.site.siteMetadata.facebook}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#000022" }}
                >
                  {data.site.siteMetadata.facebook}
                </a>
              </p>
            </div>

            <div className={`column ${styles.footerColumn}`}>
              <h3 className="heading-underline">Bookings</h3>
              <h4>
                For Book Festivals and Conferences, contact Sandra at:
              </h4>
              <p>sandraneilwallace@gmail.com</p>

              <h4>Literary Agent/Film Rights<br/>For Inquiries, contact:
              </h4>
              <p>Liza Voges at Eden Street LLC/ lvoges@edenstreetlit.com</p>
            </div>

            <div className={`column ${styles.footerColumn}`}>
              <h3 className="heading-underline">Publicity</h3>
              <h4 className="text-uppercase">LOVE IS LOUD, MARJORY SAVES THE EVERGLADES <br/>&amp; BETWEEN THE LINES</h4>
              <p>nicole.russo@simonandschuster.com</p>

              <h4 className="text-uppercase">First Generation</h4>
              <p>Deirdre.Jones@hbgusa.com</p>

              <h4 className="text-uppercase">
              RACE AGAINST TIME,<br/>THE TEACHERS MARCH!,<br/>BOUND BY ICE,<br/>BLOOD BROTHER,<br/>&amp; BABE CONQUERS THE WORLD
              </h4>
              <p>kmcmanus@astrapublishinghouse.com</p>

              <h4 className="text-uppercase">Muckers &amp;<br/>Little Joe</h4>
              <p>stbrown@penguinrandomhouse.com</p>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
