import { StaticQuery, graphql } from "gatsby"
import React from "react"
import Image from "gatsby-image"

import Container from "../global/container"
import SectionTitle from "../section-title"
import PrimaryButton from "../button-primary"

import styles from "../button-primary.module.css"

const About = () => {
  function toggleBio() {
    let toggleText = document.querySelector(".bio-toggle")
    document.querySelector(".short-bio").toggleAttribute("hidden")
    document.querySelector(".long-bio").toggleAttribute("hidden")

    toggleText.innerText =
      toggleText.innerText === "Short Bio" ? "Long Bio" : "Short Bio"
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          gallery: file(name: { eq: "sandra-gallery" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          pressphoto: file(
            name: { eq: "sandra-neil-wallace-publicity-photo" }
          ) {
            publicURL
          }
        }
      `}
      render={data => (
        <>
          <section
            id="about"
            style={{ paddingBottom: "5rem" }}
            aria-labelledby="about-header"
          >
            <Container>
              <SectionTitle
                id="about-header"
                titleClass="title--dark"
                title="About"
              />

              <div className="row">
                <div className="column">
                  <div className="long-bio">
                    <p>
                      <strong>
                        SANDRA NEIL WALLACE IS AN AUTHOR AND ADVOCATE
                      </strong>{" "}
                      for change. Known for her investigative journalism and
                      original narrative style, her books for young readers
                      focus on people who break barriers and change the world.
                      As the daughter of a refugee and concentration camp
                      survivor, Sandra became a changemaker herself. The first
                      generation in her family to attend university, she became
                      a journalist, anchoring the network news before shattering
                      the glass ceiling in sports television as the first woman
                      to host an NHL broadcast on national TV. She is among the first journalists to report on the WNBA. Sandra's books have
                      won national awards including NCTE's Orbis Pictus Award
                      for Outstanding Nonfiction, the NCSS Carter G. Woodson
                      Book Award, SCBWI's Golden Kite Honor Award, and ILA's
                      Social Justice Literature Award, and been chosen as
                      Outstanding Science Books and Best Books by the American
                      Library Association, Kirkus, Booklist, and the New York
                      and Chicago Public Libraries.
                    </p>

                    <p>
                      Sandra became a U.S. citizen in 2016 and advocates for
                      social responsibility in her community as co-founder of{" "}
                      <a
                        href="https://www.keene.edu/news/stories/detail/scholarship-recognizes-first-generation-students-honors-family-ancestries/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        The Koenig Siddall First Generation Scholarship Fund
                      </a>
                      , and{" "}
                      <a
                        href="https://dailygoodnh.org/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        The Daily Good
                      </a>
                      , an all-volunteer non-profit bringing 25 thousand
                      culturally diverse foods to college students each year,
                      through its Global Foods Pantries at New Hampshire
                      colleges. She is the recipient of the Outstanding Women of
                      New Hampshire Award for peace &amp; nonviolence and the
                      Keene Sentinel’s Extraordinary Women Award, and New Hampshire’s Granite State Award for outstanding contributions to the welfare of the state. Sandra lives
                      in southern New Hampshire with her husband and frequent
                      collaborator, author Rich Wallace.
                    </p>
                  </div>
                  <div className="short-bio" hidden>
                    <p>
                      Since breaking a gender barrier in sports as the first
                      woman to anchor an NHL broadcast on national TV,{" "}
                      <strong>Sandra Neil Wallace</strong> has been writing
                      stories about people who break barriers and change the
                      world. She is the author of the Orbis Pictus Award-winning
                      book{" "}
                      <em>
                        Between the Lines: How Ernie Barnes Went from the
                        Football Field to the Art Gallery
                      </em>
                      , illustrated by Bryan Collier, which earned 4 starred
                      reviews and co-author of the Orbis Pictus Honor Winner{" "}
                      <em>The Teachers March!</em>, and the Carter G. Woodson
                      awarding-winning book, <em>Race Against Time</em>. Her young adult
                      nonfiction title, <em>Blood Brother</em>, won The
                      International Literacy Association's Social Justice Award
                      and was a YALSA Award Nominee for Excellence in
                      Nonfiction. The daughter of a Yugoslavian refugee, Sandra
                      lives in New Hampshire where she continues to break
                      barriers and create change as co-founder of{" "}
                      <a
                        href="https://dailygoodnh.org/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        The Daily Good.
                      </a>
                    </p>
                  </div>
                  <button
                    className={`bio-toggle ${styles.button} ${styles.button} ${styles.primary}`}
                    onClick={toggleBio}
                  >
                    Short Bio
                  </button>
                </div>

                <div className="column">
                  <Image
                    fluid={data.gallery.childImageSharp.fluid}
                    alt="Sandra Wallace Headshot"
                  />

                  {data.pressphoto ? (
                    <aside style={{ marginTop: "2rem", textAlign: "center" }}>
                      <PrimaryButton
                        buttonText="Publicity Photo"
                        buttonLink={data.pressphoto.publicURL}
                        buttonType="primary"
                        target="_blank"
                      />
                    </aside>
                  ) : null}
                </div>
              </div>
            </Container>
          </section>
        </>
      )}
    />
  )
}

export default About
