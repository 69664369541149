import React from "react"
import YouTube from "react-youtube"
import styles from "./latest.module.css"
import Image from "gatsby-image"
import BannerHeading from "../banner-heading"
import ButtonPrimary from "../button-primary"
import LinkGroup from "../link-group"
import { useStaticQuery } from "gatsby"

const Latest = () => {
  const latestBooks = useStaticQuery(graphql`
    query latestBooks {
      star: file(relativePath: { eq: "images/reviews/review-star.svg" }) {
        publicURL
      }
      book1: booksJson(slug: { eq: "love-is-loud" }) {
        title
        subtitle
        slug
        shortdescription
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        links {
          indiebound
          amazon
          barnes_and_noble
          bookshop
        }
        discussion_guide {
          publicURL
        }
      }
      book2: booksJson(slug: { eq: "race-against-time" }) {
        title
        subtitle
        slug
        shortdescription
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        discussion_guide {
          publicURL
        }
        links {
          indiebound
          amazon
          barnes_and_noble
          bookshop
        }
      }
      book3: booksJson(slug: { eq: "marjory-saves-the-everglades" }) {
        title
        subtitle
        slug
        shortdescription
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        discussion_guide {
          publicURL
        }
        additional_resources {
          name
          link {
            publicURL
          }
        }
        links {
          indiebound
          amazon
          barnes_and_noble
          bookshop
        }
      }
    }
  `)

  return (
    <>
      <section
        id="latest"
        className={styles.latestBooks}
        aria-labelledby="latest-releases-header"
      >
        <h2
          id="latest-releases-header"
          style={{
            fontSize: "1.3rem",
            marginBottom: "0",
            position: "relative",
            textAlign: "center",
            transform: "translateY(22px)",
            zIndex: "802",
          }}
        >
          <BannerHeading bannerText="Latest Releases" />
        </h2>

        <div className={styles.latest} style={{ gridGap: "0" }}>
          {/* Book 1 */}
          <div className={("column", styles.latestBook)}>
            <article className={styles.bookArticle}>
              <div className={styles.header}>
                <figure className={styles.image}>
                  <Image
                    fluid={latestBooks.book1.image.childImageSharp.fluid}
                    alt={`${latestBooks.book1.title} Cover`}
                  />
                </figure>

                <header className={styles.headerInfo}>
                  <h3 className={styles.title}>{latestBooks.book1.title}</h3>
                  <p
                    className={styles.subtitle}
                    style={{ marginBottom: "1rem" }}
                  >
                    {latestBooks.book1.subtitle}
                  </p>
                  <p className={styles.description}>
                    {latestBooks.book1.shortdescription}
                  </p>
                  <ButtonPrimary
                    buttonText="More Info"
                    buttonType="primary"
                    buttonSize="small"
                    buttonLink={`book/${latestBooks.book1.slug}`}
                  />
                </header>
              </div>

              <footer className={styles.footer}>
                <p className={styles.pullQuoteHeader}>5 Starred Reviews!</p>
                <blockquote className={styles.pullQuote}>
                  <img
                    src={latestBooks.star.publicURL}
                    className="review-star"
                    alt="Star icon"
                  />
                  "MOVING … A poignant and powerful portrayal of the life and
                  work of an unsung civil rights activist."
                  <cite>(Kirkus, starred review)</cite>
                </blockquote>
                <div className={styles.ctaGroup}>
                  <LinkGroup
                    indiebound={latestBooks.book1.links.indiebound}
                    amazon={latestBooks.book1.links.amazon}
                    barnes_and_noble={latestBooks.book1.links.barnes_and_noble}
                    bookshop={latestBooks.book1.links.bookshop}
                  />
                </div>
                <div className={styles.trailer}>
                  <h4
                    style={{
                      textAlign: "center",
                      marginTop: "0",
                      marginBottom: "1rem",
                    }}
                  >
                    Watch the Trailer
                  </h4>
                  <YouTube videoId="T6ZsySlemnU" />
                </div>
                {latestBooks.book1.discussion_guide ? (
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "0",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <ButtonPrimary
                      buttonText="Discussion Guide"
                      buttonType="primary"
                      buttonSize="wide"
                      buttonLink={latestBooks.book1.discussion_guide.publicURL}
                      target="_blank"
                    />
                  </p>
                ) : null}
              </footer>
            </article>
          </div>
          {/* Book 2 */}
          <div className={("column", styles.latestBook)}>
            <article className={styles.bookArticle}>
              <div className={styles.header}>
                <figure className={styles.image}>
                  <Image
                    fluid={latestBooks.book2.image.childImageSharp.fluid}
                    alt={`${latestBooks.book2.title} Cover`}
                  />
                </figure>

                <header className={styles.headerInfo}>
                  <h3 className={styles.title}>{latestBooks.book2.title}</h3>
                  <p
                    className={styles.subtitle}
                    style={{ marginBottom: "1rem" }}
                  >
                    {latestBooks.book2.subtitle}
                  </p>
                  <p className={styles.description}>
                    {latestBooks.book2.shortdescription}{" "}
                    <strong
                      style={{
                        fontFamily:
                          "'Josefin Sans', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
                      }}
                    ></strong>
                  </p>
                  <ButtonPrimary
                    buttonText="More Info"
                    buttonType="primary"
                    buttonSize="small"
                    buttonLink={`book/${latestBooks.book2.slug}`}
                  />
                </header>
              </div>

              <footer className={styles.footer}>
                <p className={styles.pullQuoteHeader}>
                  2 Starred Reviews! <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2ch"
                    viewBox="0 0 576 512"
                    fill="#ecc700"
                  >
                    <path d="M572.1 82.38C569.5 71.59 559.8 64 548.7 64h-100.8c.2422-12.45 .1078-23.7-.1559-33.02C447.3 13.63 433.2 0 415.8 0H160.2C142.8 0 128.7 13.63 128.2 30.98C127.1 40.3 127.8 51.55 128.1 64H27.26C16.16 64 6.537 71.59 3.912 82.38C3.1 85.78-15.71 167.2 37.07 245.9c37.44 55.82 100.6 95.03 187.5 117.4c18.7 4.805 31.41 22.06 31.41 41.37C256 428.5 236.5 448 212.6 448H208c-26.51 0-47.99 21.49-47.99 48c0 8.836 7.163 16 15.1 16h223.1c8.836 0 15.1-7.164 15.1-16c0-26.51-21.48-48-47.99-48h-4.644c-23.86 0-43.36-19.5-43.36-43.35c0-19.31 12.71-36.57 31.41-41.37c86.96-22.34 150.1-61.55 187.5-117.4C591.7 167.2 572.9 85.78 572.1 82.38zM77.41 219.8C49.47 178.6 47.01 135.7 48.38 112h80.39c5.359 59.62 20.35 131.1 57.67 189.1C137.4 281.6 100.9 254.4 77.41 219.8zM498.6 219.8c-23.44 34.6-59.94 61.75-109 81.22C426.9 243.1 441.9 171.6 447.2 112h80.39C528.1 135.7 526.5 178.7 498.6 219.8z" />
                  </svg>`,
                    }}
                  />{" "}
                  9 Awards!
                </p>
                <blockquote className={styles.pullQuote}>
                  <img
                    src={latestBooks.star.publicURL}
                    className="review-star"
                    alt="Star icon"
                  />
                  "COMPELLING ... (a) testimonial of an often-overlooked
                  landmark event in the early history of civil rights."
                  <cite>(Booklist, starred review)</cite>
                </blockquote>
                <div className={styles.ctaGroup}>
                  <LinkGroup
                    indiebound={latestBooks.book2.links.indiebound}
                    amazon={latestBooks.book2.links.amazon}
                    barnes_and_noble={latestBooks.book2.links.barnes_and_noble}
                    bookshop={latestBooks.book2.links.bookshop}
                  />
                </div>
                {latestBooks.book2.discussion_guide ? (
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "0",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <ButtonPrimary
                      buttonText="Discussion Guide"
                      buttonType="primary"
                      buttonSize="block"
                      buttonLink={latestBooks.book2.discussion_guide.publicURL}
                      target="_blank"
                    />
                  </p>
                ) : null}
              </footer>
            </article>
          </div>
          {/* Book 3 */}
          <div className={("column", styles.latestBook)}>
            <article className={styles.bookArticle}>
              <div className={styles.header}>
                <figure className={styles.image}>
                  <Image
                    fluid={latestBooks.book3.image.childImageSharp.fluid}
                    alt={`${latestBooks.book3.title} Cover`}
                  />
                </figure>

                <header className={styles.headerInfo}>
                  <h3 className={styles.title}>{latestBooks.book3.title}</h3>
                  <p
                    className={styles.subtitle}
                    style={{ marginBottom: "1rem" }}
                  >
                    {latestBooks.book3.subtitle}
                  </p>
                  <p className={styles.description}>
                    {latestBooks.book3.shortdescription}{" "}
                    <strong
                      style={{
                        fontFamily:
                          "'Josefin Sans', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
                      }}
                    ></strong>
                  </p>
                  <ButtonPrimary
                    buttonText="More Info"
                    buttonType="primary"
                    buttonSize="small"
                    buttonLink={`book/${latestBooks.book3.slug}`}
                  />
                </header>
              </div>

              <footer className={styles.footer}>
                <p className={styles.pullQuoteHeader}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `<!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2ch"
                    viewBox="0 0 576 512"
                    fill="#ecc700"
                  >
                    <path d="M572.1 82.38C569.5 71.59 559.8 64 548.7 64h-100.8c.2422-12.45 .1078-23.7-.1559-33.02C447.3 13.63 433.2 0 415.8 0H160.2C142.8 0 128.7 13.63 128.2 30.98C127.1 40.3 127.8 51.55 128.1 64H27.26C16.16 64 6.537 71.59 3.912 82.38C3.1 85.78-15.71 167.2 37.07 245.9c37.44 55.82 100.6 95.03 187.5 117.4c18.7 4.805 31.41 22.06 31.41 41.37C256 428.5 236.5 448 212.6 448H208c-26.51 0-47.99 21.49-47.99 48c0 8.836 7.163 16 15.1 16h223.1c8.836 0 15.1-7.164 15.1-16c0-26.51-21.48-48-47.99-48h-4.644c-23.86 0-43.36-19.5-43.36-43.35c0-19.31 12.71-36.57 31.41-41.37c86.96-22.34 150.1-61.55 187.5-117.4C591.7 167.2 572.9 85.78 572.1 82.38zM77.41 219.8C49.47 178.6 47.01 135.7 48.38 112h80.39c5.359 59.62 20.35 131.1 57.67 189.1C137.4 281.6 100.9 254.4 77.41 219.8zM498.6 219.8c-23.44 34.6-59.94 61.75-109 81.22C426.9 243.1 441.9 171.6 447.2 112h80.39C528.1 135.7 526.5 178.7 498.6 219.8z" />
                  </svg>`,
                    }}
                  />{" "}
                  4 Awards!
                </p>
                <p className={styles.pullQuote}>
                  Awarded OUTSTANDING SCIENCE TRADE BOOK by The National Science
                  Teaching Association, and THE CHILDREN’S BOOK AWARD by the
                  Florida Literacy Association.
                </p>
                <div className={styles.ctaGroup}>
                  <LinkGroup
                    indiebound={latestBooks.book3.links.indiebound}
                    amazon={latestBooks.book3.links.amazon}
                    barnes_and_noble={latestBooks.book3.links.barnes_and_noble}
                    bookshop={latestBooks.book3.links.bookshop}
                  />
                </div>
                <div className={styles.trailer}>
                  <h4
                    style={{
                      textAlign: "center",
                      marginTop: "0",
                      marginBottom: "1rem",
                    }}
                  >
                    Watch the Trailer
                  </h4>
                  <YouTube videoId="HDxBk03o1m0" />
                  {latestBooks.book3.discussion_guide ? (
                    <p style={{ textAlign: "center", marginTop: "2rem" }}>
                      <ButtonPrimary
                        buttonText="Discussion Guide"
                        buttonType="primary"
                        buttonSize="wide"
                        buttonLink={
                          latestBooks.book3.discussion_guide.publicURL
                        }
                        target="_blank"
                      />
                    </p>
                  ) : null}
                </div>
              </footer>
            </article>
          </div>
        </div>
      </section>
    </>
  )
}

export default Latest
